import React from "react";
import { Routes, Route } from "react-router-dom";

import Home from "./pages/Home";
import Capabilities from "./pages/Capabilities";
import FocusPlanner from "./pages/Focus Planner";
import Eventcamp from "./pages/Eventcamp";
import RepairIt from "./pages/RepairIt";
import RemoveTrailingSlash from "./hooks/removeTrailingSlash";

function App() {
  return (
    <div className="App">
      <RemoveTrailingSlash />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/capabilities" element={<Capabilities />} />
        <Route path="/focus-planner" element={<FocusPlanner />} />
        <Route path="/eventcamp" element={<Eventcamp />} />
        <Route path="/repairit" element={<RepairIt />} />
      </Routes>
    </div>
  );
}

export default App;
