import "./home.css";
import * as React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import useProgressiveImg from "../../hooks/useProgressiveImg";

const Home = () => {
  const [src, { blur }] = useProgressiveImg(
    "./background-tiny.jpg",
    "./background.jpg"
  );

  return (
    <main>
      <motion.div
        animate={{ opacity: 1 }}
        transition={{ ease: "easeOut", duration: 1.5 }}
        className="background"
        style={{
          backgroundImage: 'url(' + src + ')',
          filter: blur ? "blur(20px)" : "none",
          transition: blur ? "none" : "filter 0.3s ease-out",
          opacity: 0,
        }}
      >
        {/* <img
          className="background-image"
          alt="Background Gradient"
          src={src}
        /> */}
      </motion.div>
      <div className="container">
        <motion.div
          animate={{ y: 20, opacity: 1 }}
          transition={{ ease: "easeOut", duration: 1.5 }}
          style={{ opacity: 0 }}
        >
          <h1>Hey! I'm Oleksii</h1>
        </motion.div>
        <div className="spacer-large"></div>
        <motion.div
          animate={{ y: 20, opacity: 1 }}
          transition={{ ease: "easeOut", duration: 1.5, delay: 0.25 }}
          style={{ opacity: 0 }}
        >
          <p className="body">
            I'm a digital product designer with over 6 years of experience. Over
            the years I've worked in a variety of domains and industries, from
            Big4 firms to one-man startups.
          </p>

          <div className="spacer-small"></div>

          <p className="body">
            I endeavor toward work that makes a difference. My evidence-based
            method helps me eliminate the guesswork and get best results
            possible.
          </p>
        </motion.div>
        <div className="spacer-large"></div>
        <motion.div
          className="buttons"
          animate={{ y: 20, opacity: 1 }}
          transition={{ ease: "easeOut", duration: 1.5, delay: 0.5 }}
          style={{ opacity: 0 }}
        >
          <a href="https://upwork.com/fl/ttrnk">
            <button className="primary">
              <svg width="40" height="40" viewBox="0 0 40 40">
                <path
                  d="M20 36C28.8366 36 36 28.8366 36 20C36 11.1634 28.8366 4 20 4C11.1634 4 4 11.1634 4 20C4 28.8366 11.1634 36 20 36ZM22.7896 21.1512C23.5408 21.7592 24.3672 22.1328 25.2488 22.1328C25.822 22.1322 26.3717 21.9043 26.7771 21.4991C27.1826 21.0939 27.4109 20.5444 27.412 19.9712C27.4116 19.3978 27.1836 18.8481 26.7782 18.4426C26.3727 18.0372 25.823 17.8092 25.2496 17.8088C23.6576 17.8088 23.144 19.3424 22.9784 20.2568L22.972 20.2904L22.7896 21.1512ZM21.0008 19.1136C21.6408 16.94 23.2176 15.6208 25.2488 15.6208C27.6488 15.6208 29.6 17.572 29.6 19.972C29.6 22.3704 27.6488 24.332 25.2488 24.332C24.128 24.332 23.1504 23.988 22.3 23.4448L21.3296 28.0128H19.092L20.4304 21.7144C19.7968 20.9208 19.2744 20.0232 18.8512 19.1376V20.0896C18.8512 22.4208 16.9552 24.332 14.6248 24.332C12.296 24.332 10.4 22.4208 10.4 20.0896V14.4H12.5888V20.0896C12.5892 20.6297 12.8038 21.1475 13.1855 21.5297C13.5671 21.9118 14.0847 22.1269 14.6248 22.128C15.1651 22.1272 15.683 21.9121 16.0649 21.53C16.4469 21.1479 16.6618 20.6299 16.6624 20.0896V14.4H18.8512C19.2824 15.8848 20.0248 17.6464 21.0008 19.1136Z"
                  fill="white"
                />
              </svg>
              Upwork Profile
            </button>
          </a>
          <Link to="/capabilities">
            <button className="secondary">
              <svg width="40" height="40" viewBox="0 0 40 40">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M20.0002 9.10001C16.6754 9.10001 13.6578 10.66 11.2451 12.4986C8.82417 14.3436 6.92303 16.5368 5.81064 17.9609C5.35113 18.5423 5.10001 19.2596 5.10001 20C5.10001 20.7404 5.35114 21.4577 5.81067 22.0391C6.92306 23.4632 8.8242 25.6565 11.2451 27.5014C13.6578 29.3401 16.6754 30.9 20.0002 30.9C23.3251 30.9 26.3426 29.3401 28.7554 27.5014C31.1776 25.6555 33.0795 23.4608 34.1917 22.0367C35.1363 20.8266 35.1352 19.1739 34.1916 17.9632C33.0794 16.5391 31.1776 14.3445 28.7554 12.4986C26.3426 10.66 23.3251 9.10001 20.0002 9.10001ZM7.22752 19.0711C8.28981 17.7109 10.0836 15.6469 12.3361 13.9303C14.5981 12.2065 17.2343 10.9 20.0002 10.9C22.7661 10.9 25.4023 12.2065 27.6643 13.9303C29.9166 15.6467 31.7102 17.7104 32.7726 19.0706C33.2094 19.6313 33.2087 20.3706 32.7728 20.929C31.7105 22.2893 29.9168 24.3532 27.6643 26.0697C25.4023 27.7935 22.7661 29.1 20.0002 29.1C17.2343 29.1 14.5981 27.7935 12.3361 26.0697C10.0836 24.3531 8.28982 22.2891 7.22754 20.9289L7.22374 20.9241C7.01279 20.6577 6.90001 20.3326 6.90001 20C6.90001 19.6674 7.01281 19.3423 7.22376 19.0759L7.22752 19.0711Z"
                  fill="black"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M20 16.9C18.2879 16.9 16.9 18.2879 16.9 20C16.9 21.7121 18.2879 23.1 20 23.1C21.7121 23.1 23.1 21.7121 23.1 20C23.1 18.2879 21.7121 16.9 20 16.9ZM15.1 20C15.1 17.2938 17.2938 15.1 20 15.1C22.7062 15.1 24.9 17.2938 24.9 20C24.9 22.7062 22.7062 24.9 20 24.9C17.2938 24.9 15.1 22.7062 15.1 20Z"
                  fill="black"
                />
              </svg>
              Capabilities Deck
            </button>
          </Link>
          <a href="mailto:hey@ttrnk.com">
            <button className="secondary">
              <svg width="40" height="40" viewBox="0 0 40 40">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M24 10.8H16C13.5921 10.8 11.9672 10.8038 10.7535 10.967C9.59152 11.1232 9.07931 11.3952 8.73726 11.7373C8.39521 12.0793 8.12323 12.5915 7.96701 13.7535C7.80382 14.9672 7.8 16.5921 7.8 19V21.4C7.8 23.8079 7.80382 25.4328 7.96701 26.6465C8.12323 27.8085 8.39521 28.3207 8.73726 28.6627C9.07931 29.0048 9.59152 29.2768 10.7535 29.433C11.9672 29.5962 13.5921 29.6 16 29.6H24C26.4079 29.6 28.0328 29.5962 29.2465 29.433C30.4085 29.2768 30.9207 29.0048 31.2627 28.6627C31.6048 28.3207 31.8768 27.8085 32.033 26.6465C32.1962 25.4328 32.2 23.8079 32.2 21.4V19C32.2 16.5921 32.1962 14.9672 32.033 13.7535C31.8768 12.5915 31.6048 12.0793 31.2627 11.7373C30.9207 11.3952 30.4085 11.1232 29.2465 10.967C28.0328 10.8038 26.4079 10.8 24 10.8ZM7.46447 10.4645C6 11.9289 6 14.286 6 19V21.4C6 26.114 6 28.4711 7.46447 29.9355C8.92893 31.4 11.286 31.4 16 31.4H24C28.714 31.4 31.0711 31.4 32.5355 29.9355C34 28.4711 34 26.114 34 21.4V19C34 14.286 34 11.9289 32.5355 10.4645C31.0711 9 28.714 9 24 9H16C11.286 9 8.92893 9 7.46447 10.4645Z"
                  fill="#040505"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.20343 11.4739L8.39654 10.1261L15.2938 16.2319C16.5738 17.365 17.471 18.157 18.2332 18.6759C18.9743 19.1804 19.4918 19.3578 20 19.3578C20.5082 19.3578 21.0257 19.1804 21.7668 18.6759C22.5289 18.157 23.4262 17.365 24.7061 16.2319L31.6034 10.1261L32.7965 11.4739L25.853 17.6206C24.6301 18.7032 23.6479 19.5728 22.7797 20.1638C21.8796 20.7766 21.0079 21.1578 20 21.1578C18.9921 21.1578 18.1204 20.7766 17.2202 20.1638C16.3521 19.5728 15.3699 18.7032 14.147 17.6206L7.20343 11.4739Z"
                  fill="#040505"
                />
              </svg>
              Email Me
            </button>
          </a>
        </motion.div>
        <motion.div
          className="ukraine"
          layout
          animate={{ y: [-20, 0], opacity: 1 }}
          transition={{ ease: "easeOut", duration: 1.5, delay: 0.75 }}
          style={{ opacity: 0 }}
        >
          <p className="caption">
            🇺🇦 Help my country win! Visit{" "}
            <a
              href="https://war.ukraine.ua"
              target="_blank"
              rel="noopener noreferrer"
            >
              war.ukraine.ua
            </a>{" "}
            to learn more.
          </p>
        </motion.div>
      </div>
    </main>
  );
};

export default Home;
