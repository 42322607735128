import React, { useState } from "react";
import useDimensions from "../../hooks/useWindowDimensions";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { motion } from "framer-motion";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "./capabilities.css";
import useProgressiveImg from "../../hooks/useProgressiveImg";

const Capabilities = () => {
  const [numPages, setNumPages] = useState(null);
  function onDocumentLoadSuccess({ numPages: nextNumPages }) {
    setNumPages(nextNumPages);
  }

  const { width } = useDimensions();

  const [src, { blur }] = useProgressiveImg(
    "./first-slide-tiny.jpg",
    "./first-slide.jpg"
  );

  return (
    <div className="react-pdf-container">
      <motion.div
        animate={{ opacity: 1 }}
        transition={{ ease: "easeOut", duration: 1.5 }}
        className="pdf-background"
        style={{
          filter: blur ? "blur(20px)" : "none",
          transition: blur ? "none" : "filter 0.3s ease-out",
          opacity: 0,
          zIndex: "-1",
        }}
      >
        <img className="first-slide" src={src} alt="First slide" />
      </motion.div>
      <motion.div
        className="loading"
        animate={{ y: 20, opacity: 1 }}
        transition={{ ease: "easeOut", duration: 0.5 }}
        style={{ x: "-50%", opacity: 0 }}
      >
        <div class="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>

        <div className="loading-container">
          <motion.div
            className="loading-content"
            animate={{ height: "auto" }}
            transition={{ ease: "easeOut", duration: 0.5, delay: 10 }}
            style={{ height: 20 }}
          >
            <div className="loading-title">Loading, please wait</div>
            <motion.div
              className="loading-description"
              animate={{ opacity: 1 }}
              transition={{ ease: "easeOut", duration: 0.5, delay: 10 }}
              style={{ opacity: 0 }}
            >
              If this takes too long, please refresh the page
            </motion.div>
          </motion.div>
        </div>
      </motion.div>

      <Document
        file="./capabilities-deck.pdf"
        onLoadSuccess={onDocumentLoadSuccess}
        error={null}
        loading={null}
        noData={null}
      >
        <Page
          className="first-page"
          width={width - 24}
          pageNumber={1}
          loading={null}
        />
        {Array.from(new Array(numPages), (el, index) => (
          <Page
            className="other-pages"
            width={width - 24}
            key={`page_${index + 1}`}
            pageNumber={index + 1 + 1}
            loading={null}
            error={null}
          />
        ))}
      </Document>
    </div>
  );
};

export default Capabilities;
